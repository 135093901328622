import styled from "styled-components"

import Container from "@/components/Container"

export const StepsRonSectionRoot = styled.section`
  margin-bottom: 83px;
  @media only screen and (min-width: 1025px) {
    margin-bottom: 218px;
  }
`
export const MyContainer = styled(Container)`
  .title-wrap {
    margin-bottom: 5rem;
  }
  .title {
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.03em;
    color: #000000;
    span {
      background-color: #ffc700;
      padding: 0.2rem;
    }
  }

  .card-steps {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5.8rem;
  }
  .card:last-child .line {
    display: none;
  }
  .card:last-child {
    margin-bottom: 0;
  }

  .card-row {
    display: flex;
    align-items: center;
  }
  .num {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #161617;
    font-weight: normal;
    font-size: 1.73rem;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #ffffff;
  }
  .card-desc {
    margin-left: 2.1rem;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #161617;
  }
  .line {
    position: absolute;
    left: 1.75rem;
    width: 1px;
    height: 90%;
    top: 50%;
    transform: translateY(-50%);
    border-right: 1px dashed #999999;
    z-index: -1;
  }

  @media only screen and (min-width: 768px) {
    .card-steps {
      flex-direction: row;
      justify-content: space-between;
    }
    .card {
      width: 27%;
      margin-bottom: 0;
    }
    .card:last-child {
      width: 19%;
    }
    .card-row {
      display: block;
      align-items: stretch;
    }
    .num {
      width: 45px;
      height: 45px;
      margin-bottom: 2.7rem;
      font-size: 2.2rem;
    }
    .card-desc {
      letter-spacing: -0.035em;
      margin-left: 0;
      padding-right: 1rem;
      max-width: 280px;
    }
    .line {
      left: 0;
      top: 23px;
      width: 83%;
      height: 1px;
      border-bottom: 1px dashed #999999;
      border-right: none;
    }
  }

  @media only screen and (min-width: 1025px) {
    .title-wrap {
      margin-bottom: 10rem;
    }
    .title {
      font-size: 4rem;
      text-align: center;
    }

    .card-desc {
      font-size: 2rem;
    }
    .num {
      width: 54px;
      height: 54px;
      font-size: 2.6rem;
    }
    .line {
      top: 27.5px;
    }
  }

  @media only screen and (min-width: 1440px) {
    .title {
      font-size: 4.6rem;
    }
    .card-desc {
      font-size: 2.2rem;
    }
  }
`
