import { forwardRef, useState } from "react"

import { Link } from "gatsby"
import { useTheme } from "styled-components"
import { AnimatePresence, motion } from "framer-motion"
import usePagination from "@/hooks/usePagination"

import Container from "@/components/Container"
import Typography from "@/components/Typography"

import {
  FAQSectionRoot,
  FAQSectionList,
  FAQSectionListItem,
  FAQSectionListItemMob,
  FAQSectionListTitle,
  FAQSectionListText,
  FAQSectionListItemButton,
  FAQSectionIconWrap,
} from "./FAQSection.style"

import { LongArrowRightIcon } from "@/icons"

const FAQSection = forwardRef(
  ({ data, limit = 10, onScrollToElement = false, isMD }, ref) => {
    const { data: pageData } = usePagination(data, limit)
    const { colors } = useTheme()
    const [index, setIndex] = useState(0)

    const handleClickItem = idx => {
      idx = idx === index ? -1 : idx
      setIndex(idx)
    }
    const Item = isMD ? FAQSectionListItemMob : FAQSectionListItem

    return (
      <FAQSectionRoot id="faq" ref={ref}>
        <Container>
          <Typography fontSize={46} fontWeight={600}>
            FAQs
          </Typography>
          <FAQSectionList>
            {pageData.map((item, idx) => {
              const isExpanded = idx === index
              return (
                <Item
                  key={idx}
                  onClick={isMD ? () => handleClickItem(idx) : undefined}
                >
                  <FAQSectionListTitle>
                    <span>{item.title}</span>
                    <FAQSectionIconWrap>
                      <svg
                        width="1em"
                        viewBox="0 0 16 15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <motion.path
                          d="M8 3.125V11.875"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          animate={{
                            rotate: isExpanded ? -90 : 0,
                            color: isExpanded ? colors.primary : colors.black,
                          }}
                          transition={{ ease: "easeInOut" }}
                        />
                        <motion.path
                          d="M3.625 7.5H12.375"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          animate={{ opacity: isExpanded ? 0 : 1 }}
                          transition={{ ease: "easeInOut" }}
                        />
                      </svg>
                    </FAQSectionIconWrap>
                  </FAQSectionListTitle>
                  {isMD ? (
                    <AnimatePresence>
                      {isExpanded && (
                        <motion.div
                          initial="collapsed"
                          animate="open"
                          exit="collapsed"
                          variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 },
                          }}
                          transition={{
                            duration: 0.3,
                            ease: "easeInOut",
                          }}
                          style={{ overflow: "hidden" }}
                        >
                          <FAQSectionListText>{item.text}</FAQSectionListText>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  ) : (
                    <FAQSectionListText>{item.text}</FAQSectionListText>
                  )}
                </Item>
              )
            })}
            {onScrollToElement ? (
              <div
                style={{
                  backgroundColor: "#FFC700",
                  padding: "25px 18px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={onScrollToElement}
              >
                <h4
                  style={{
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "120%",
                    letterSpacing: "-0.015em",
                    color: "#000",
                  }}
                >
                  Get your Certification
                </h4>
                <p
                  style={{
                    color: "#060606",
                    letterSpacing: "-0.035em",
                    fontSize: "14px",
                    lineHeight: "140%",
                    maxWidth: "263px",
                  }}
                >
                  Take the course to start applying for a Remote Online Notary
                  commission
                </p>
              </div>
            ) : (
              <FAQSectionListItemButton as={Link} to="/resources">
                <FAQSectionListTitle>
                  <span>Read More</span>
                  <LongArrowRightIcon />
                </FAQSectionListTitle>
                <FAQSectionListText>
                  Remote Notary Knowledge Center
                </FAQSectionListText>
              </FAQSectionListItemButton>
            )}
          </FAQSectionList>
        </Container>
      </FAQSectionRoot>
    )
  }
)

export default FAQSection
