import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

const ButtonCourse = ({ render: RenderButton }) => {
  const [status, setStatus] = useState("")

  useEffect(() => {
    ;(async function onLoad() {
      const Auth = (await import("@/aws/AmplifyAuthConfigure")).default
      Auth.currentSession()
        .then(() => {
          setStatus("Go back to Course")
        })
        .catch(() => setStatus("Course Sign In"))
    })()
  }, [])

  return (
    <RenderButton
      to={status === "Course Sign In" ? "/login" : "/course"}
      as={Link}
    >
      {status}
    </RenderButton>
  )
}

export default ButtonCourse
