import styled from "styled-components"

import Container from "@/components/Container"

export const RonAdvantagesSectionRoot = styled.section`
  background-color: #0e0f10;
  padding-top: 60px;
  padding-bottom: 64px;

  @media only screen and (min-width: 1025px) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  @media only screen and (min-width: 1368px) {
    padding-top: 180px;
    padding-bottom: 186px;
  }
`

export const MyContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 4%;

  .title-wrap {
    margin-bottom: 5.5rem;
  }
  .desktop {
    display: none;
  }
  .title {
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 100%;
    letter-spacing: -0.03em;
    color: #ffffff;
    span {
      color: #0e0f10;
      background-color: #ffc700;
      padding: 0.2rem;
    }
  }

  .left-column {
    display: flex;
    flex-direction: column;
    margin-bottom: 7.2rem;

    .adv-stat {
      display: flex;
      align-items: center;
      margin-bottom: 80px;
      .count {
        font-weight: 500;
        font-size: 7rem;
        line-height: 2.9rem;
        letter-spacing: -1px;
        color: #ffc700;
      }
      .info {
        font-size: 1.4rem;
        line-height: 139.34%;
        letter-spacing: -0.035em;
        color: #999999;
        max-width: 137px;
        border-left: 1px solid #c4c4c4;
        padding-left: 2.3rem;
        margin-left: 3.4rem;
      }
    }

    .img-container {
      margin-bottom: 4.5rem;
    }

    .ron-monthly {
      font-size: 1.2rem;
      line-height: 120%;
      letter-spacing: -0.035em;
      color: #999999;
      span {
        font-size: 1.8rem;
        line-height: 22px;
        letter-spacing: -1px;
        color: #ffffff;
      }
    }
  }

  .right-column {
    span {
      position: relative;
    }
    .list-item {
      margin-bottom: 3.2rem;
      list-style-type: none;
      display: flex;
      gap: 1rem;
    }
    .list-item:last-child {
      margin-bottom: 0;
    }
    .item-desc {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 120%;
      letter-spacing: -0.01em;
      color: #fafafa;
      margin-bottom: 1rem;
    }
    .item-sub-desc {
      font-size: 1.4rem;
      line-height: 139.34%;
      letter-spacing: -0.035em;
      color: #595959;
    }
  }

  @media only screen and (min-width: 1025px) {
    flex-direction: row;
    justify-content: space-between;

    .title-wrap {
      margin-bottom: 3.5rem;
    }
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
    .title {
      font-size: 3.6rem;
    }

    .left-column {
      flex: 1;
      max-width: 587px;
      margin-bottom: 0;

      .adv-stat {
        .count {
          font-size: 7rem;
          line-height: 2.2rem;
        }
        .info {
          font-size: 1.8rem;
          line-height: 120%;
          max-width: 205px;
          padding-left: 4rem;
          margin-left: 4.4rem;
        }
      }

      .img-container {
        margin-bottom: 8.2rem;
      }

      .ron-monthly {
        font-size: 1.8rem;
        span {
          font-size: 2.5rem;
        }
      }
    }
    .right-column {
      max-width: 666px;
      flex: 1;
      .list-item {
        margin-bottom: 4.3rem;
        gap: 2rem;
      }
      .item-desc {
        font-size: 2rem;
        letter-spacing: -0.015em;
      }
      .item-sub-desc {
        font-size: 1.4rem;
        line-height: 120%;
        letter-spacing: -0.03em;
      }
    }
  }

  @media only screen and (min-width: 1368px) {
    .title-wrap {
      margin-bottom: 6.5rem;
    }
    .title {
      font-size: 4rem;
    }

    .left-column {
      .adv-stat {
        .count {
          font-size: 7.2rem;
        }
        .info {
          font-size: 2rem;
        }
      }

      .ron-monthly {
        font-size: 2rem;
        span {
          font-size: 3rem;
        }
      }
    }
    .right-column {
      .item-desc {
        font-size: 2.4rem;
      }
      .item-sub-desc {
        font-size: 1.6rem;
      }
    }
  }

  @media only screen and (min-width: 1440px) {
    gap: 8%;
    .title-wrap {
      margin-bottom: 8rem;
    }
    .title {
      font-size: 4.6rem;
    }
    .left-column {
      .adv-stat {
        .count {
          font-size: 9rem;
        }
      }
    }
  }
`
