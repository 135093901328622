import { Link } from "gatsby"
import styled from "styled-components"

import Container from "@/components/Container"
import { ButtonBase } from "@/components/NotarizationButton"
import Typography from "@/components/Typography"
import usePagination from "@/hooks/usePagination"
import { LongArrowRightIcon } from "@/icons"
import { flexbox } from "@mui/system"

const FAQSectionRoot = styled.section`
  padding: 150px 0;
`

const FAQSectionList = styled.div`
  margin-top: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 140px;
  row-gap: 95px;

  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 140px;
    row-gap: 95px;
  }
`

const FAQSectionListItem = styled.div`
  position: relative;
  color: #4d4d4d;
`

const FAQSectionListTitle = styled.h4`
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;

  svg {
    font-size: 14px;
    margin-right: 14px;
  }
`

const FAQSectionListText = styled.p`
  margin-top: 24px;
  font-size: 14px;
  color: #777;
  line-height: 1.9;
`

const FAQSectionListItemButton = styled(ButtonBase)`
  padding: 15px 34px;
  display: block;
  text-align: left;
  border-left: 4px solid ${p => p.theme.colors.primary};
  transition: 100ms ease-in-out;

  &:hover {
    background: #fafafa;
  }

  ${FAQSectionListTitle} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
  }
`

function FAQSection({ data, limit = 10, onScrollToElement = false }) {
  const { data: pageData } = usePagination(data, limit)

  return (
    <FAQSectionRoot id="faq">
      <Container>
        <Typography fontSize={46} fontWeight={600}>
          FAQs
        </Typography>
        <FAQSectionList>
          {pageData.map((item, idx) => (
            <FAQSectionListItem key={idx}>
              <FAQSectionListTitle>
                <span>{item.title}</span>
              </FAQSectionListTitle>
              <FAQSectionListText>{item.text}</FAQSectionListText>
            </FAQSectionListItem>
          ))}
          <FAQSectionListItemButton as={Link} to="/resources">
            <FAQSectionListTitle>
              <span>Read More</span>
              <LongArrowRightIcon />
            </FAQSectionListTitle>
            <FAQSectionListText>
              Remote Notary Knowledge Center
            </FAQSectionListText>
          </FAQSectionListItemButton>
          {onScrollToElement && (
            <div
              style={{
                backgroundColor: "#FFC700",
                padding: "25px 18px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={onScrollToElement}
            >
              <h4
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "120%",
                  letterSpacing: "-0.015em",
                  color: "#000",
                }}
              >
                Get your Certification
              </h4>
              <p
                style={{
                  color: "#060606",
                  letterSpacing: "-0.035em",
                  fontSize: "14px",
                  lineHeight: "140%",
                  maxWidth: "263px",
                }}
              >
                Take the course to start applying for a Remote Online Notary
                commission
              </p>
            </div>
          )}
        </FAQSectionList>
      </Container>
    </FAQSectionRoot>
  )
}

export default FAQSection