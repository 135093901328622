import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import list from "./ronAdv.data"
import SecondaryTitle from "@/components/SecondaryTitle"
import { RonAdvantagesSectionRoot, MyContainer } from "./RonAdv.style.js"
import { CheckMarkYellowIcon } from "@/icons"

const RonAdvantagesSection = () => {
  return (
    <RonAdvantagesSectionRoot>
      <MyContainer>
        <div className="title-wrap mobile">
          <SecondaryTitle className="title">
            <span>Validity</span> of RON practice
          </SecondaryTitle>
        </div>

        <div className="column left-column">
          <div className="adv-stat">
            <p className="count">11K</p>
            <p className="info">Current number of RON notaries in Florida</p>
          </div>
          <div className="img-container">
            <StaticImage
              src="../../images/graph-ron.png"
              alt="graph-ron"
              style={{ width: "100%" }}
            />
          </div>
          <p className="ron-monthly">
            <span>211 / month</span> - Notaries public becoming RON certified
          </p>
        </div>

        <div className="column right-column">
          <div className="title-wrap desktop">
            <SecondaryTitle className="title">
              <span>Validity</span> of RON practice
            </SecondaryTitle>
          </div>
          <ul className="list">
            {list.map(({ itemDesc, itemSubdesc }) => {
              return (
                <li className="list-item" key={itemDesc}>
                  <span>{<CheckMarkYellowIcon />}</span>
                  <div>
                    <h3 className="item-desc">{itemDesc}</h3>
                    <p className="item-sub-desc">{itemSubdesc}</p>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </MyContainer>
    </RonAdvantagesSectionRoot>
  )
}
export default RonAdvantagesSection
