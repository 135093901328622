import { useMemo, useState } from "react";

import chunk from "lodash/chunk";

const usePagination = (data, limit = 4) => {
  const [page, setPage] = useState(0);

  const chunkedData = useMemo(() => chunk(data, limit), [data, limit]);

  const pagesArray = useMemo(
    () =>
      new Array(chunkedData.length).fill("").map((_, pageIndex) => pageIndex),
    [data]
  );

  return {
    data: chunkedData[page] || [],
    pages: chunkedData.length,
    pagesArray,
    page,
    setPage,
  };
};

export default usePagination;
