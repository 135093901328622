import styled from "styled-components"

import Container from "@/components/Container"
import { ButtonBase } from "@/components/NotarizationButton"

export const HeaderSectionRoot = styled.section`
  position: relative;
  background-color: #141517;
  h1 strong {
    font-weight: 600;
  }
  margin-bottom: 8rem;

  @media only screen and (min-width: 1025px) {
    margin-bottom: 14rem;
  }
`

export const MyContainer = styled(Container)`
  padding-top: 3.5rem;
  padding-bottom: 5.3rem;
  display: flex;
  flex-direction: column-reverse;

  .text-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    .title {
      max-width: 630px;
      font-size: 3.2rem;
      line-height: 97.84%;
      letter-spacing: -0.035em;
    }
    .desc {
      max-width: 527px;
      margin: 3.3rem 0 5rem;
      opacity: 0.7;
      font-size: 1.4rem;
      line-height: 139.34%;
      letter-spacing: -0.035em;
    }
  }

  .button-wrap {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .video-content {
    flex: 1;
    position: relative;
    padding-top: 56.25%;
    margin-bottom: 5rem;

    .frame {
      position: absolute;
      top: 0;

      iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    padding-top: 5.5rem;
    padding-bottom: 7.3rem;
    .text-content {
      margin-right: 2rem;
      .title {
        font-size: 25px;
      }
      .desc {
        margin: 1.5rem 0 3rem;
        font-size: 12px;
      }
    }
    .video-content {
      padding-top: 26%;
      margin-bottom: 0;
    }
  }
  @media only screen and (min-width: 1025px) {
    padding-top: 22.5rem;
    padding-bottom: 13rem;
    .text-content {
      .title {
        font-size: 3.6rem;
        line-height: 100%;
      }
      .desc {
        margin: 3rem 0 5rem;
        font-size: 1.6rem;
        line-height: 120%;
      }
    }
  }

  @media only screen and (min-width: 1280px) {
    .text-content {
      .title {
        font-size: 4.5rem;
        line-height: 100%;
      }
      .desc {
        margin: 4rem 0 6rem;
        font-size: 1.8rem;
      }
    }
  }

  @media only screen and (min-width: 1440px) {
    .text-content {
      .title {
        font-size: 5.4rem;
      }
      .desc {
        margin: 6rem 0 8rem;
      }
    }
  }
`

export const BtnBuy = styled(ButtonBase)`
  width: 100%;
  height: 49px;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  color: ${p => p.theme.colors.black};
  background-color: ${p => p.theme.colors.primary};
  @media only screen and (min-width: 576px) {
    width: 284px;
  }
  @media only screen and (min-width: 768px) {
    width: 250px;
    height: 40px;
    font-size: 12px;
  }
  @media only screen and (min-width: 1025px) {
    width: 284px;
    height: 49px;
    font-size: 16px;
  }
`
export const LinkToBuy = styled.a`
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: ${p => p.theme.colors.primary};
  @media only screen and (min-width: 576px) {
    width: 284px;
  }
  @media only screen and (min-width: 768px) {
    width: 250px;
    font-size: 12px;
  }
  @media only screen and (min-width: 1025px) {
    width: 284px;
    font-size: 16px;
  }
`
