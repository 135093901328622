import styled from "styled-components"
import { ButtonBase } from "@/components/NotarizationButton"

export const FAQSectionRoot = styled.section`
  padding: 50px 0;
  @media (min-width: 1025px) {
    padding: 180px 0 200px;
  }
`

export const FAQSectionList = styled.div`
  margin-top: 25px;
  @media (min-width: 1025px) {
    margin-top: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 140px;
    row-gap: 95px;
    .mtop {
      margin-top: 0;
    }
  }

  @media (min-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const FAQSectionListItem = styled.div`
  position: relative;
  color: #4d4d4d;
`
export const FAQSectionListItemMob = styled(ButtonBase)`
  -webkit-tap-highlight-color: transparent;
  display: block;
  width: 100%;
  text-align: left;
  position: relative;
  color: #19191b;
  border-bottom: 1px solid #ebebeb;
  &:last-of-type {
    border-bottom: none;
    margin-bottom: 50px;
  }
`

export const FAQSectionListTitle = styled.h4`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0 15px;

  @media (min-width: 1025px) {
    margin: 0;
    margin-bottom: 22px;
    padding: 0;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    svg {
      font-size: 14px;
      margin-right: 14px;
    }
  }
`

export const FAQSectionIconWrap = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-left: 14px;
  font-size: 24px;
  @media (min-width: 1025px) {
    display: none;
  }
`

export const FAQSectionListText = styled.p`
  margin-bottom: 15px;
  font-size: 14px;
  color: #777;
  line-height: 1.8;
  @media (min-width: 1025px) {
    margin-bottom: 0;
  }
`

export const FAQSectionListItemButton = styled(ButtonBase)`
  padding-left: 13px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  border-left: 4px solid ${p => p.theme.colors.primary};
  transition: 100ms ease-in-out;

  &:hover {
    background: #fafafa;
  }

  ${FAQSectionListTitle} {
    margin: 0;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    svg {
      margin-left: 14px;
    }
    @media (min-width: 1025px) {
      justify-content: start;
      font-size: 23px;
    }
  }

  ${FAQSectionListText} {
    margin-top: 5px;
    @media (min-width: 1025px) {
      margin-top: 12px;
    }
  }

  @media (min-width: 1025px) {
    padding: 30px 34px;
    margin-top: 0;
  }
`
