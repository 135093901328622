import PlayFileIcon from "@/images/play-file.webp"
import LessonIcon from "@/images/lesson.webp"
import ClockIcon from "@/images/clock.webp"
import DoneIcon from "@/images/done.webp"

export const cardData = [
  {
    icon: <img src={ClockIcon} alt="clock" width={37} height={37} className="card-icon" />,
    cardTitle: "2 hours",
    cardDesc:
      "That's all it takes to complete the Ronary course. Quick and simple",
  },
  {
    icon: <img src={PlayFileIcon} alt="play" width={37} height={37} className="card-icon" />,
    cardTitle: "Video format",
    cardDesc:
      "Text material is combined with video lectures from a live instructor",
  },
  {
    icon: <img src={LessonIcon} alt="lesson" width={37} height={37} className="card-icon" />,
    cardTitle: "11 lessons",
    cardDesc: "Curriculum consists of 11 in-depth modules and a short quiz",
  },
  {
    icon: <img src={DoneIcon} alt="done" width={37} height={37} className="card-icon" />,
    cardTitle: "Certificate",
    cardDesc:
      "Ronary Certificate of Completion is recognized by the Department of State",
  },
]
export const list = [
  "About Course",
  "Chapter 1. Introduction",
  "Chapter 2. Remote Online Notarization",
  "Chapter 3. RON Procedures",
  "Chapter 4. Supervising the Witnessing",
  "Chapter 5. Preparing the Documents",
  "Chapter 6. RON Fees",
  "Chapter 7. Prohibited Acts",
  "Chapter 8. Resignations",
  "Chapter 9. RON Glossary",
  "Chapter 10. Frequently Asked Questions",
  "Quiz",
]
