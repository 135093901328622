import React from "react"

import loadable from "@loadable/component"
const ReactPlayer = loadable(() => import("react-player/lazy"))

import Title from "@/components/Title"
import Typography from "@/components/Typography"

import { HeaderSectionRoot, MyContainer, BtnBuy, LinkToBuy } from "./MainHeader.style"
import { motion } from "framer-motion"
import ButtonCourse from "@/components/ButtonCourse"
const fadeUpTransition = {
  initial: { y: "45%", opacity: 0 },
  animate: { opacity: 1, y: 0 },
  transition: { ease: [0.4, 0.0, 0.2, 1], duration: 1 },
}

const BlockType = ({ children, isMD, className, ...props }) => {
  return isMD ? (
    <div className={className}>{children}</div>
  ) : (
    <motion.div className={className} {...props}>
      {children}
    </motion.div>
  )
}

const MainHeaderSectionDesktop = ({
  title,
  desc,
  videoLink,
  animateTitle = false,
  onScrollToElement,
  isMD,
  coursePrice = 49,
}) => {
  return (
    <HeaderSectionRoot>
      <MyContainer>
        <div className="text-content">
          <Title
            className="title"
            animate={isMD && animateTitle}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <BlockType
            isMD={isMD}
            {...fadeUpTransition}
            transition={{ ...fadeUpTransition.transition }}
          >
            <Typography
              as="p"
              color="white"
              className="desc"
              dangerouslySetInnerHTML={{ __html: desc }}
            />
          </BlockType>

          <BlockType
            isMD={isMD}
            className="button-wrap"
            {...fadeUpTransition}
            transition={{ ...fadeUpTransition.transition, delay: 0.4 }}
          >
            <ButtonCourse render={BtnBuy} className="" />
            <LinkToBuy onClick={onScrollToElement}>
              Buy online course - ${coursePrice}
            </LinkToBuy>
          </BlockType>
        </div>

        <div className="video-content">
          <ReactPlayer
            light
            playing
            controls
            url={videoLink}
            playsinline={true}
            width="100%"
            height="100%"
            className="frame"
          />
        </div>
      </MyContainer>
    </HeaderSectionRoot>
  )
}

export default MainHeaderSectionDesktop
