import React, { useEffect } from "react"
import SecondaryTitle from "@/components/SecondaryTitle"

import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import data from "./stepsRon.data"
import { StepsRonSectionRoot, MyContainer } from "./StepsRon.style"

const StepsRonSection = () => {
  const [rootRef, inView] = useInView({
    threshold: 0.7,
  })
  const controls = useAnimation()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [inView])

  return (
    <StepsRonSectionRoot ref={rootRef}>
      <MyContainer>
        <div className="title-wrap">
          <SecondaryTitle className="title">
            Steps <span>to become RON</span>
          </SecondaryTitle>
        </div>
        <div className="card-steps">
          <div className="line"></div>
          {data.map((item, idx) => {
            return (
              <div className="card" key={idx}>
                <div className="card-row">
                  <div className="num">
                    <motion.div
                      initial="hidden"
                      animate={controls}
                      variants={{
                        visible: { scale: 1, opacity: 1 },
                        hidden: { scale: 1.8, opacity: 0 },
                      }}
                      transition={{ duration: 0.4, delay: 0.2 * idx }}
                    >
                      {idx + 1}
                    </motion.div>
                  </div>
                  <p className="card-desc">{item}</p>
                </div>
              </div>
            )
          })}
        </div>
      </MyContainer>
    </StepsRonSectionRoot>
  )
}
export default StepsRonSection
