import styled from "styled-components"

import Container from "@/components/Container"
import { ButtonBase } from "@/components/NotarizationButton"

export const BenefitsSectionRoot = styled.div`
  margin-bottom: 81px;
  @media only screen and (min-width: 1025px) {
    margin-bottom: 242px;
  }
`
export const MyContainer = styled(Container)`
  display: flex;
  flex-direction: column;

  .column-left {
    margin-bottom: 6.4rem;
    img {
      width: 100%;
      display: flex;
    }
  }

  .column-right {
  }
  .title-wrap {
    margin-bottom: 4.5rem;
  }
  .title {
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.03em;
    color: #282931;
    span {
      background-color: #ffc700;
      padding: 0.2rem;
    }
  }
  .tab-container {
    margin-bottom: 4rem;
  }
  .tab-list {
    list-style-type: none;
    display: flex;
    gap: 4.6rem;
    margin-bottom: 3rem;
    padding-bottom: 0.8rem;
    border-bottom: 1px solid #ededed;
  }
  .tabs {
    font-size: 2rem;
    font-weight: normal;
    line-height: 120%;
    letter-spacing: -0.035em;
    color: #161617;
    cursor: pointer;
  }
  .active-tabs {
    position: relative;
    font-weight: 600;
  }
  .active-tabs::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1rem;
    width: 100%;
    height: 3px;
    background: #ffc700;
  }
  .content {
    display: none;
  }
  .active-content {
    display: block;
  }
  .content-item {
    display: flex;
    margin-bottom: 2rem;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 139.34%;
    letter-spacing: -0.035em;
    color: #000000;
    .check-mark {
      margin-right: 1.3rem;
    }
  }
  .content-item:last-child {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1025px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 3%;

    .column-left {
      flex: 1 1 60%;
      max-width: 732px;
      margin-bottom: 0;
      img {
        width: 100%;
        display: flex;
      }
    }

    .column-right {
      flex: 1 1 47%;
      max-width: 564px;
    }
    .title-wrap {
      margin-bottom: 3.5rem;
    }
    .title {
      font-size: 3rem;
    }
    .tab-container {
      margin-bottom: 2.5rem;
    }
    .tab-list {
      margin-bottom: 3rem;
      padding-bottom: 1rem;
    }
    .content-item {
      font-size: 1.6rem;
      line-height: 120%;
      letter-spacing: -0.03em;
    }
    .content-item:nth-last-child(-n + 2) {
      display: none;
    }
  }
  @media only screen and (min-width: 1200px) {
    .title-wrap {
      margin-bottom: 4rem;
    }
    .title {
      font-size: 3.5rem;
    }
    .tab-container {
      margin-bottom: 5rem;
    }
    .tab-list {
      margin-bottom: 4rem;
    }
  }
  @media only screen and (min-width: 1360px) {
    .content-item:nth-last-child(-n + 2) {
      display: flex;
    }
  }
  @media only screen and (min-width: 1440px) {
    .title-wrap {
      margin-bottom: 4.5rem;
    }
    .title {
      font-size: 4.6rem;
    }
  }
`

export const BtnBuy = styled(ButtonBase)`
  width: 100%;
  height: 49px;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 120%;
  background-color: ${p => p.theme.colors.primary};
  color: ${p => p.theme.colors.black};
  @media only screen and (min-width: 576px) {
    width: 300px;
  }
  @media only screen and (min-width: 1025px) {
    width: 300px;
    font-size: 1.4rem;
  }
`
