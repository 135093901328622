import React, { useState, forwardRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import SecondaryTitle from "@/components/SecondaryTitle"

import items from "./coursebenefits.data"
import { CheckMarkIcon } from "@/icons"
import {
  BenefitsSectionRoot,
  BtnBuy,
  MyContainer,
} from "./CourseBenefits.style"

const CourseBenefitsSection = forwardRef((props, ref) => {
  const {
    onScrollToElement,
    coursePrice = 49,
    certificateRef,
    benefitsRef,
    liteRef,
  } = props
  const [activeTab, setActiveTab] = useState(0)

  const handleActiveTab = index => {
    setActiveTab(index)
  }

  const handleActiveClass = (index, className) => {
    return activeTab === index && className
  }

  return (
    <BenefitsSectionRoot ref={benefitsRef}>
      <MyContainer>
        <div className="column column-left" ref={certificateRef}>
          <StaticImage
            src="../../images/certificate-white.png"
            alt="certificate-images"
          />
        </div>
        <div className="column column-right">
          <div className="title-wrap">
            <SecondaryTitle className="title">
              RON <span>benefits</span> you can get
            </SecondaryTitle>
          </div>
          <div className="tab-container" ref={liteRef}>
            <ul className="tab-list">
              <li
                className={`tabs ${handleActiveClass(0, "active-tabs")}`}
                onClick={() => handleActiveTab(0)}
              >
                Business
              </li>
              <li
                className={`tabs ${handleActiveClass(1, "active-tabs")}`}
                onClick={() => handleActiveTab(1)}
              >
                Individual
              </li>
            </ul>

            <div className="tab-content">
              <div
                className={`content ${handleActiveClass(0, "active-content")}`}
              >
                {items["bussiness"].map(text => (
                  <p className="content-item" key={text}>
                    <span className="check-mark">{<CheckMarkIcon />}</span>
                    {text}
                  </p>
                ))}
              </div>

              <div
                className={`content ${handleActiveClass(1, "active-content")}`}
              >
                {items["individual"].map(text => (
                  <p className="content-item" key={text}>
                    <span className="check-mark">{<CheckMarkIcon />}</span>
                    {text}
                  </p>
                ))}
              </div>
            </div>
          </div>

          <BtnBuy onClick={onScrollToElement}>
            Buy Course - ${coursePrice}
          </BtnBuy>
        </div>
      </MyContainer>
    </BenefitsSectionRoot>
  )
})

export default CourseBenefitsSection
