import { useEffect } from "react";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

const SecondaryTitleContainer = styled.div`
  overflow: hidden;
`;

const SecondaryTitleRoot = styled(motion.h2)`
  font-weight: 600;
  font-size: 46px;
  line-height: 1.2;

  span {
    background: ${(p) => p.theme.colors.primary};
    color: ${(p) => p.theme.colors.black};
    padding: 0 8px;
  }

  @media (max-width: 1024px) {
    font-size: 28px;
  }
`;

const variants = {
  hidden: { y: "100%" },
  visible: { y: 0 },
};

function SecondaryTitle({ children, delay, animate = true, ...rest }) {
  const [ref, inView] = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView && animate) {
      controls.start("visible");
    }
  }, [inView, animate]);

  return (
    <SecondaryTitleContainer ref={ref}>
      <SecondaryTitleRoot
        initial={animate ? "hidden" : "visible"}
        animate={controls}
        variants={variants}
        transition={{ duration: 0.6, delay }}
        {...rest}
      >
        {children}
      </SecondaryTitleRoot>
    </SecondaryTitleContainer>
  );
}

export default SecondaryTitle;
