import { useState } from "react"

import { AnimatePresence, motion } from "framer-motion"
// import { Link } from "gatsby"
import styled, { useTheme } from "styled-components"

import Container from "@/components/Container"
import { ButtonBase } from "@/components/NotarizationButton"
import Typography from "@/components/Typography"
import usePagination from "@/hooks/usePagination"
import { LongArrowRightIcon } from "@/icons"

const FAQSectionRoot = styled.section`
  padding: 50px 0;
`

const FAQSectionList = styled.div`
  margin-top: 25px;
`

const FAQSectionListItem = styled(ButtonBase)`
  -webkit-tap-highlight-color: transparent;
  display: block;
  width: 100%;
  text-align: left;
  position: relative;
  color: #19191b;
  border-bottom: 1px solid #ebebeb;
`

const FAQSectionListTitle = styled.h4`
  padding: 10px 0;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const FAQSectionIconWrap = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-left: 14px;
  font-size: 24px;
`

const FAQSectionListText = styled.p`
  font-size: 14px;
  color: #777;
  padding-bottom: 15px;
`

const FAQSectionListItemButton = styled(ButtonBase)`
  margin-top: 35px;
  padding: 0 13px;
  display: block;
  text-align: left;
  border-left: 4px solid ${p => p.theme.colors.primary};
  transition: 100ms ease-in-out;

  ${FAQSectionListTitle} {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    padding: 0;
  }

  ${FAQSectionListText} {
    margin-top: 5px;
    padding-bottom: 0;
  }
`

function FAQSection({ data, limit = 10, onScrollToElement = false }) {
  const { data: pageData } = usePagination(data, limit)
  const { colors } = useTheme()
  const [index, setIndex] = useState(1)

  const handleClickItem = idx => {
    idx = idx === index ? -1 : idx
    setIndex(idx)
  }

  return (
    <FAQSectionRoot>
      <Container>
        <Typography fontSize={46} fontWeight={600}>
          FAQs
        </Typography>
        <FAQSectionList>
          {pageData.map((item, idx) => {
            const isExpanded = idx === index
            return (
              <FAQSectionListItem
                key={idx}
                onClick={() => handleClickItem(idx)}
              >
                <FAQSectionListTitle>
                  <span>{item.title}</span>
                  <FAQSectionIconWrap>
                    <svg
                      width="1em"
                      viewBox="0 0 16 15"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <motion.path
                        d="M8 3.125V11.875"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        animate={{
                          rotate: isExpanded ? -90 : 0,
                          color: isExpanded ? colors.primary : colors.black,
                        }}
                        transition={{ ease: "easeInOut" }}
                      />
                      <motion.path
                        d="M3.625 7.5H12.375"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        animate={{ opacity: isExpanded ? 0 : 1 }}
                        transition={{ ease: "easeInOut" }}
                      />
                    </svg>
                  </FAQSectionIconWrap>
                </FAQSectionListTitle>
                <AnimatePresence>
                  {isExpanded && (
                    <motion.div
                      initial="collapsed"
                      animate="open"
                      exit="collapsed"
                      variants={{
                        open: { opacity: 1, height: "auto" },
                        collapsed: { opacity: 0, height: 0 },
                      }}
                      transition={{
                        duration: 0.3,
                        ease: "easeInOut",
                      }}
                      style={{ overflow: "hidden" }}
                    >
                      <FAQSectionListText>{item.text}</FAQSectionListText>
                    </motion.div>
                  )}
                </AnimatePresence>
              </FAQSectionListItem>
            )
          })}
          {onScrollToElement && (
            <div
              style={{
                backgroundColor: "#FFC700",
                padding: "25px 18px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={onScrollToElement}
            >
              <h4
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "120%",
                  letterSpacing: "-0.015em",
                  color: "#000",
                }}
              >
                Get your Certification
              </h4>
              <p
                style={{
                  color: "#060606",
                  letterSpacing: "-0.035em",
                  fontSize: "14px",
                  lineHeight: "140%",
                  maxWidth: "263px",
                }}
              >
                Take the course to start applying for a Remote Online Notary
                commission
              </p>
            </div>
          )}
        </FAQSectionList>
      </Container>
    </FAQSectionRoot>
  )
}

export default FAQSection