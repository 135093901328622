import styled from "styled-components"
import Container from "@/components/Container"
import { ButtonBase } from "@/components/NotarizationButton"

export const FeaturesRonSectionRoot = styled.section`
  margin-bottom: 80px;

  @media only screen and (min-width: 1025px) {
    margin-bottom: 180px;
  }
`

export const MyContainer = styled(Container)`
  padding: 0;
  .features-cards,
  .title-wrap,
  .left-col {
    padding: 0 24px;
  }
  .title-wrap {
    margin-bottom: 4rem;
  }
  .title {
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 100%;
    letter-spacing: -0.03em;
    color: #25272b;
    span {
      background-color: #ffc700;
      padding: 0.2rem;
    }
  }

  .features-container {
    margin-bottom: 8rem;
  }
  .features-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 5%;
    row-gap: 5rem;
    margin-bottom: 8rem;
  }
  .card {
    flex: 1 1 45%;
    color: #25272b;
    .card-title {
      font-weight: 600;
      font-size: 1.7rem;
      line-height: 22px;
      letter-spacing: -0.015em;
      margin-bottom: 1rem;
    }
    .card-desc {
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 140%;
      letter-spacing: -0.015em;
    }
    .icon-wrap {
      width: 37px;
      height: 37px;
      margin-bottom: 2.5rem;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .offer-container {
    display: flex;
    flex-direction: column;
    gap: 10.6rem;
  }
  .left-col {
    width: 100%;
    color: #25272b;
    .caption {
      margin-bottom: 4.8rem;
      font-size: 2.8rem;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -0.03em;
    }
    .list-item {
      margin-bottom: 28px;
      padding-bottom: 25px;
      list-style-type: none;
      font-size: 16px;
      font-weight: 600;
      line-height: 119.8%;
      letter-spacing: -0.045em;
      color: #25272b;
      border-bottom: 1px solid #00000014;
    }
    .list-item:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  .right-col {
    padding: 5.5rem 2.4rem 4.7rem;
    background-color: #25272b;
    font-weight: normal;

    .holder {
      position: relative;
    }
    .err-backend {
      position: absolute;
      top: -4rem;
      left: 0;
      font-size: 14px;
      line-height: 15px;
      letter-spacing: -0.025em;
      color: #ff5454;
    }
    .loading {
      background-color: #c4c4c4;
    }

    h3 {
      font-weight: 600;
      font-size: 26px;
      line-height: 120%;
      letter-spacing: -0.015em;
      color: #ffffff;
      margin-bottom: 2rem;
    }
    .p {
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.03em;
      color: #c4c4c4;
      margin-bottom: 15px;
    }
    ul {
      margin-bottom: 29px;
      display: flex;
      list-style-type: none;
      li {
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #adaeaf;
      }
      .st-act {
        margin-right: 27px;
        font-weight: 600;
        color: #ffffff;
        border-bottom: 2px solid #ffc700;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      input {
        padding: 1.5rem 0;
        color: #fff;
        caret-color: #fff;
      }
      input::placeholder {
        color: #fff;
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #fff !important;
      }
      .row {
        margin: 0;
        display: block;
      }
      .row-item {
        margin: 0 0 1.5rem;
        border-bottom: 1px solid #c4c4c4;
      }
      .flex-group {
        margin-top: 25px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column-reverse;
        .img-wrap {
          margin-bottom: 45px;
          display: flex;
          align-items: center;
          gap: 25px;
          .visa {
            width: 47px;
            height: auto;
          }
          .master {
            width: 34px;
            height: auto;
          }
        }
      }
    }

    .confirm {
      display: flex;
      align-items: center;
      p {
        position: relative;
        top: 0.1rem;
        font-size: 1rem;
        line-height: 119.8%;
        letter-spacing: -0.015em;
        color: #ffffff;
        margin-left: 0.7rem;
        a {
          color: #b58d00;
          text-decoration: underline;
        }
      }
    }

    .approved {
      display: flex;
      align-items: center;
      p {
        width: 126px;
        margin-bottom: 0;
        margin-right: 36px;
        font-size: 12px;
        line-height: 130%;
        letter-spacing: -0.035em;
        color: #ffffff;
      }
      img {
        width: 80px;
        height: auto;
      }
    }
  }

  @media only screen and (min-width: 421px) {
    padding: 0;
    .title-wrap,
    .features-cards,
    .left-col,
    .right-col {
      padding-right: 32px;
      padding-left: 32px;
    }
  }
  @media only screen and (min-width: 480px) {
    .right-col {
      form {
        .row {
          display: flex;
          gap: 4rem;
        }
        .row-item {
          /* flex-grow: 1; */
          width: 50%;
          margin-bottom: 3.5rem;
        }
        .row-item:nth-child(even) {
          /* margin-left: 4rem; */
        }
      }
    }
  }
  @media only screen and (min-width: 481px) {
    padding: 0;
    .title-wrap,
    .features-cards,
    .left-col,
    .right-col {
      padding-right: 48px;
      padding-left: 48px;
    }
  }
  @media only screen and (min-width: 576px) {
    .right-col {
      form {
        margin-bottom: 39px;
        .flex-group {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 45px;
          .img-wrap {
            margin-bottom: 0;
          }
        }
        .confirm {
          p {
            font-size: 1.15rem;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .features-container {
      margin-bottom: 12rem;
    }
    .features-cards {
      flex-wrap: nowrap;
      column-gap: 4rem;
      margin-bottom: 12rem;
    }
    .card {
      flex: 1 1 300px;
      .icon-wrap {
        margin-bottom: 3rem;
      }
      .card-title {
        font-size: 18px;
      }
      .card-desc {
        font-size: 14px;
      }
    }
    .left-col {
      .caption {
        font-weight: 600;
        line-height: 119.8%;
        letter-spacing: -0.045em;
      }
      .list-item {
        margin-bottom: 26.69px;
        padding-bottom: 22.21px;
      }
    }
    .right-col {
      .p {
        margin-bottom: 35px;
      }
    }
  }

  @media only screen and (min-width: 1025px) {
    padding: 0 48px;
    .title-wrap,
    .features-cards,
    .left-col {
      padding: 0;
    }
    .title-wrap {
      margin-bottom: 8rem;
    }
    .title {
      font-size: 3.6rem;
    }
    .features-cards {
      column-gap: 6rem;
    }
    .card {
      .card-title {
        font-size: 2rem;
      }
      .card-desc {
        font-size: 16px;
      }
      .icon-wrap {
        width: 44px;
        height: 44px;
      }
    }
    .offer-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 5%;
    }
    .col {
      flex: 1;
    }
    .left-col {
      max-width: 625px;
    }
    .right-col {
      .confirm {
        p {
          margin-left: 1.1rem;
          font-size: 1.3rem;
        }
      }
      form {
        .flex-group {
          margin-bottom: 39px;
        }
      }
    }
  }
  @media only screen and (min-width: 1280px) {
    .left-col {
      .caption {
        margin-bottom: 7.2rem;
        font-size: 3.4rem;
      }
      .list-item {
        font-size: 20.46px;
      }
    }
    .right-col {
      padding: 8.2rem 6.5rem 8rem;
      max-width: 625px;
      background-color: #25272b;
      h3 {
        font-size: 32px;
        letter-spacing: -0.03em;
        margin-bottom: 6.3rem;
      }
      .p {
        font-size: 16px;
        margin-bottom: 55px;
      }
      ul {
        margin-bottom: 53px;
        li {
          font-size: 22px;
        }
        .st-act {
          margin-right: 40px;
        }
      }

      form {
        .flex-group {
          margin-top: 30px;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
      .money-back {
        margin-bottom: 48px;
      }
    }
  }
  @media only screen and (min-width: 1401px) {
    padding: 0 96px;
  }
  @media only screen and (min-width: 1440px) {
    .title {
      font-size: 4.6rem;
    }
  }
`
export const BtnBuy = styled(ButtonBase)`
  width: 100%;
  height: 49px;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  color: ${p => p.theme.colors.black};
  background-color: ${p => p.theme.colors.primary};
  @media only screen and (min-width: 576px) {
    width: 300px;
  }
  @media only screen and (min-width: 1025px) {
    font-size: 16px;
  }
`
