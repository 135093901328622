import { forwardRef, useState, useEffect } from "react"
import { list, cardData } from "./FeaturesRon.data"
import {
  FeaturesRonSectionRoot,
  MyContainer,
  BtnBuy,
} from "./FeaturesRon.style"
import master from "@/images/mastercard-img.webp"
import visa from "@/images/visa-img.webp"
import approved from "@/images/approved-img.webp"
import TextField from "@/components/FormFields/TextField"
import PhoneFields from "@/components/FormFields/PhoneField"
import SecondaryTitle from "@/components/SecondaryTitle"
import Checkbox from "@mui/material/Checkbox"
import { Formik } from "formik"
import Auth from "@/aws/AmplifyAuthConfigure"
import { createUserUtils } from "@/utils"
import { getLeadYupSchema } from "@/helpers"

const FeaturesRonSection = forwardRef((props, ref) => {
  const { coursePrice = 49, featuresRonRef, publicRef } = props
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState(false)

  const handleSubmit = async values => {
    const { checked, ...restValues } = values
    if (!checked) return

    setLoading(true)

    await Auth.currentSession()
      .then(async session => {
        const jwtToken = session.getAccessToken().getJwtToken()
        if (jwtToken) await Auth.signOut()
      })
      .catch(error => {})

    try {
      const res = await createUserUtils({ ...restValues, coursePrice }).then(
        ({ data }) => JSON.parse(data.createUserLead)
      )
      if (res.statusCode !== 200) throw `${res.body}`
      setLoading(false)
      window.location.replace(res.body)
    } catch (err) {
      setLoading(false)
      setErr(err)
    }
  }

  useEffect(() => {
    let seto
    if (err) {
      seto = setTimeout(() => {
        setErr(false)
      }, 5000)
    }
    return () => clearTimeout(seto)
  }, [err])

  return (
    <FeaturesRonSectionRoot>
      <MyContainer>
        <div className="title-wrap" ref={publicRef}>
          <SecondaryTitle className="title">
            Features of RON <span>course</span>
          </SecondaryTitle>
        </div>
        <div className="features-container">
          <div className="features-cards">
            {cardData.map(({ icon, cardTitle, cardDesc }, idx) => {
              return (
                <div className="card" key={cardTitle}>
                  <div className="icon-wrap">{icon}</div>
                  <p className="card-title">{cardTitle}</p>
                  <p className="card-desc">{cardDesc}</p>
                </div>
              )
            })}
          </div>
          <div className="offer-container">
            <div className="col left-col">
              <h3 className="caption">Course video modules</h3>
              <ul className="list">
                {list.map((item, idx) => (
                  <li className="list-item" key={idx}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            <div className="col right-col" ref={featuresRonRef}>
              <div className="holder">
                <p className="err-backend">{err && `\u002A ${err}`}</p>
                <h3>Buy "Ronary Certification Course"</h3>
                <p className="p">
                  This unique Florida RON course will provide you with the best
                  educational material, informational resources, and support
                  service.
                </p>
                <ul>
                  <li className="st-act">Step 1</li>
                  <li>Step 2</li>
                </ul>
              </div>
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: "",
                  phone: "",
                  promocode: "",
                  checked: true,
                }}
                validationSchema={getLeadYupSchema()}
                onSubmit={handleSubmit}
              >
                {({ values, handleSubmit, setFieldValue }) => (
                  <form onSubmit={handleSubmit} id="contact">
                    <div className="row">
                      <div className="row-item">
                        <TextField
                          name="firstName"
                          value={values.firstName}
                          placeholder="First Name"
                          id="data_firstName"
                        />
                      </div>
                      <div className="row-item">
                        <TextField
                          name="lastName"
                          value={values.lastName}
                          placeholder="Last Name"
                          id="data_lastName"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="row-item">
                        <TextField
                          name="email"
                          type="email"
                          value={values.email}
                          placeholder="Email"
                          id="data_email"
                        />
                      </div>
                      <div className="row-item">
                        <PhoneFields
                          name="phone"
                          value={values.phone}
                          placeholder="Phone"
                          id="data_phone"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="row-item">
                        <TextField
                          type="text"
                          name="promocode"
                          maxLength="15"
                          value={values.promocode}
                          placeholder="Promo code (optional)"
                          id="data_promocode"
                        />
                      </div>
                      <div
                        className="row-item"
                        style={{ marginBottom: 0, border: "none" }}
                      ></div>
                    </div>
                    <div className="flex-group">
                      <BtnBuy
                        type="submit"
                        disabled={loading}
                        className={loading ? "loading" : ""}
                      >
                        Buy online course - ${coursePrice}
                      </BtnBuy>
                      <div className="img-wrap">
                        <img
                          className="master"
                          heigh={34}
                          height={21}
                          src={master}
                          alt="mastercard"
                        />
                        <img
                          className="visa"
                          width={47}
                          height={15}
                          src={visa}
                          alt="visa"
                        />
                      </div>
                    </div>
                    <div className="confirm">
                      <Checkbox
                        checked={values.checked}
                        onChange={() =>
                          setFieldValue("checked", !values.checked)
                        }
                        sx={{
                          padding: 0,
                          color: "#FFC700",
                          "&.Mui-checked": {
                            color: "#FFC700",
                          },
                          "& .MuiSvgIcon-root": { fontSize: 22 },
                        }}
                      />
                      <p>
                        I agree for Ronary to process my personal data according
                        to its{" "}
                        <a
                          href="https://ronary.com/terms-of-use/"
                          target={"_blank"}
                        >
                          Terms of Use
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://ronary.com/privacy-policy/"
                          target={"_blank"}
                        >
                          Privacy Policy
                        </a>
                        .
                      </p>
                    </div>
                  </form>
                )}
              </Formik>
              <div className="approved">
                <p>Approved by the Florida Department of State</p>
                <div>
                  <img src={approved} width={80} height={80} alt="approved" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MyContainer>
    </FeaturesRonSectionRoot>
  )
})

export default FeaturesRonSection
