import * as React from "react"

import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function Seo({
  description,
  lang,
  meta,
  image: metaImage,
  title,
  pathname,
  url,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  )

  const image =
    metaImage && metaImage.images.fallback.src
      ? `${site.siteMetadata.siteUrl}${metaImage.images.fallback.src}`
      : null
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null
  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
      // script={[
      //   {
      //     src: "//code-eu1.jivosite.com/widget/uwIeEEOi6d",
      //     type: "text/javascript",
      //   },
      //   {
      //     src: "https://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js",
      //     defer: "true",
      //   },
      //   {
      //     src: "https://www.googletagmanager.com/gtag/js?id=AW-369471881",
      //   },
      //   {
      //     type: "text/javascript",
      //     innerHTML: `window.dataLayer = window.dataLayer || [];
      //                 function gtag(){dataLayer.push(arguments);}
      //                 gtag('js', new Date());
      //                 gtag('config', 'AW-369471881');
      //     `,
      //   },
      // ]}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: canonical,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          metaImage
            ? [
                {
                  property: "og:image",
                  content: image,
                },
                {
                  property: "og:image:width",
                  content: metaImage.width,
                },
                {
                  property: "og:image:height",
                  content: metaImage.height,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
              ]
            : [
                {
                  name: "twitter:card",
                  content: "summary",
                },
              ]
        )
        .concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    images: PropTypes.object,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
}

export default Seo
