import React, { Fragment, useState, createRef, useRef, useEffect } from "react"

import { graphql } from "gatsby"

import useMediaQuery from "@/hooks/useMediaQuery"
import Seo from "@/components/Seo"
import MainHeaderSection from "@/sections/MainHeaderSection"
import FeaturesRonSection from "@/sections/FeaturesRonSection"
import StepsRonSection from "@/sections/StepsRonSection/StepsRonSection"
import CourseBenefitsSection from "@/sections/CourseBenefitsSection"
import RonAdvantagesSection from "@/sections/RonAdvantagesSection"
import FAQSection from "@/sections/FAQSection"
import FAQMain from "@/data/faq-main.json"
// import FooterSection from "@/sections/FooterSection"
import loadable from "@loadable/component"
const CourseModal = loadable(() => import("@/components/CourseModal"))
const FreeModuleForm = loadable(() => import("@/components/FreeModuleForm"))

export default function Main({ data }) {
  const { shareFile, strapi } = data
  const isMD = useMediaQuery({ maxWidth: 1024 })
  const [showModal, setToggleModal] = useState(false)
  const certificateRef = useRef()
  const publicRef = useRef()
  const liteRef = useRef()
  const faqRef = useRef()
  const featuresRonRef = useRef()
  const benefitsRef = useRef()

  const hashMap = {
    public: publicRef,
    ref: featuresRonRef,
    free: benefitsRef,
    certificate: certificateRef,
    lite: liteRef,
    individual: liteRef,
    business: liteRef,
    faq: faqRef,
  }

  const scrollToElement = hash => {
    hashMap[hash].current.scrollIntoView({ behavior: "smooth" })
  }
  const handleIntersection = (entries, observer) => {
    const [entry] = entries
    if (entry.isIntersecting) {
      setToggleModal(true)
      observer.unobserve(entry.target)
    }
  }
  const options = {
    root: null,
    rootMargin: "-100px",
    thresHold: 1,
  }

  useEffect(() => {
    if (window.location.hash) {
      const hash = window.location.hash.split("#")[1]
      scrollToElement(hash)
    }
    setTimeout(() => {
      const observer = new IntersectionObserver(handleIntersection, options)
      observer.observe(benefitsRef.current)
    }, 1500)
  }, [isMD])

  return (
    <Fragment>
      <Seo
        title={strapi.onlineCourse.seo.metaTitle}
        description={strapi.onlineCourse.seo.metaDescription}
        image={shareFile.childImageSharp.gatsbyImageData}
        pathname={strapi.onlineCourse.seo.pathName}
      />
      <MainHeaderSection
        title={strapi.onlineCourse.hero.title}
        desc={strapi.onlineCourse.hero.description}
        videoLink={strapi.onlineCourse.hero.videoLink}
        onScrollToElement={() => scrollToElement("ref")}
        isMD={isMD}
      />
      <FeaturesRonSection
        featuresRonRef={featuresRonRef}
        publicRef={publicRef}
      />
      <StepsRonSection />
      <CourseBenefitsSection
        onScrollToElement={() => scrollToElement("ref")}
        certificateRef={certificateRef}
        benefitsRef={benefitsRef}
        liteRef={liteRef}
      />
      <RonAdvantagesSection />
      <FAQSection
        data={FAQMain}
        onScrollToElement={() => scrollToElement("ref")}
        isMD={isMD}
        ref={faqRef}
      />
      {/* <FooterSection /> */}
      <CourseModal showModal={showModal} onToggleModal={setToggleModal}>
        <FreeModuleForm onToggleModal={val => setToggleModal(val)} />
      </CourseModal>
    </Fragment>
  )
}

export const query = graphql`
  {
    strapi {
      onlineCourse {
        seo {
          ...seo
        }
        hero {
          ...modHero
        }
      }
    }
    shareFile: file(relativePath: { eq: "share-home.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, height: 627, layout: FIXED)
      }
    }
  }
`
